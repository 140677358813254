import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Hidden, Typography } from "@mui/material";
import { AppContext } from "../../App";
import InternetSpeed from "./InternetSpeed";
import { useSelector, shallowEqual } from "react-redux";
import { sessionsSelector } from "../../redux/session/selector";
import { authSelector, UserProfile } from "redux/auth/selector";
import { UserRoles } from "enums/roles";
import { ReactComponent as ActiveIcon } from "assets/infoBar-icon/Active.svg";
import { ReactComponent as InactiveIcon } from "assets/infoBar-icon/Inactive.svg";
import { ReactComponent as EscalatedIcon } from "assets/infoBar-icon/escalated.svg";
import { AppConfig } from "enums/config";

interface Props {}

const StaticsView = (props: Props) => {
  const [t, theme, minPcMatch] = useContext(AppContext);
  const { profile }: UserProfile = useSelector(authSelector);
  const align = "left";

  const activeCount: number = useSelector(
    (state) => sessionsSelector(state).activeCount,
    shallowEqual
  );
  const inactiveCount: number = useSelector(
    (state) => sessionsSelector(state).inactiveCount,
    shallowEqual
  );
  const escalatedUnassigned: object = useSelector(
    (state) => sessionsSelector(state).escalatedUnassigned,
    shallowEqual
  );

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        sx={{
          background: minPcMatch
            ? theme.palette.primary.light
            : theme.palette.general.white,
          borderRadius: minPcMatch
            ? AppConfig.NO_BORDER_RADIUS
            : AppConfig.BORDER_RADIUS_MIN,
          border: minPcMatch
            ? "none"
            : `1px solid ${theme.palette.general.grayDark}`,
          pl: "1em",
          minWidth: "20em",
        }}
      >
        {profile.role === UserRoles.SUPERVISOR && (
          <Grid
            item
            container
            xs={12}
            lg={2}
            justifyContent={minPcMatch ? "flex-start" : "center"}
            textAlign={minPcMatch ? align : "center"}
            alignSelf="center"
            p={minPcMatch ? ".5em" : 0}
          >
            <span
              title={t("active")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <EscalatedIcon
                width={"1.1rem"}
                height={"1.1rem"}
                fill={
                  minPcMatch
                    ? theme.palette.general.offWhite
                    : theme.palette.general.darkPrimary
                }
              />
              <Hidden smUp={true}>
                <span
                  style={{
                    padding: "0 0.2em",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {t("escalated")}
                </span>
              </Hidden>
            </span>

            <Typography
              color={theme.palette.text.secondary}
              sx={{ px: "0.2em" }}
            >
              {Object.keys(escalatedUnassigned).length}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          lg={"auto"}
          justifyContent={minPcMatch ? "flex-start" : "center"}
          textAlign={minPcMatch ? align : "center"}
          alignSelf="center"
          p={minPcMatch ? ".5em" : 0}
        >
          <span
            title={t("active")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ActiveIcon
              width={"1.2rem"}
              height={"1.2rem"}
              fill={
                minPcMatch
                  ? theme.palette.general.offWhite
                  : theme.palette.general.darkPrimary
              }
            />
            <Hidden smUp={true}>
              <span
                style={{
                  padding: "0 0.2em",
                  color: theme.palette.general.offWhite,
                }}
              >
                {t("active")}
              </span>
            </Hidden>
          </span>

          <Typography color={theme.palette.success.main} sx={{ px: "0.2em" }}>
            {activeCount}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={"auto"}
          justifyContent={minPcMatch ? "flex-start" : "center"}
          textAlign={minPcMatch ? align : "center"}
          alignSelf="center"
          sx={{
            borderRight: minPcMatch
              ? "none"
              : `1px solid ${theme.palette.general.grayLighter}`,
          }}
          p={minPcMatch ? ".5em" : 0}
        >
          <span
            title={t("queued")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <InactiveIcon
              width={"1.2rem"}
              height={"1.2rem"}
              fill={
                minPcMatch
                  ? theme.palette.general.offWhite
                  : theme.palette.general.darkPrimary
              }
            />
            <Hidden smUp={true}>
              <span
                style={{
                  padding: "0 0.2em",
                  color: theme.palette.general.offWhite,
                }}
              >
                {t("queued")}
              </span>
            </Hidden>
          </span>

          <Typography color={theme.palette.error.main} sx={{ px: "0.2em" }}>
            {inactiveCount}
          </Typography>
        </Grid>

        <Hidden lgDown={true}>
          <Grid
            item
            container
            xs={1}
            height="2em"
            justifyContent="space-between"
            sx={{ minWidth: "10%" }}
          >
            {/* <Grid item xs={6}>
            <Notification />
          </Grid> */}
            <Grid item xs={6} alignSelf="end" textAlign="center" mt="0.3em">
              <InternetSpeed />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default StaticsView;
